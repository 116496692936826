import { Toggle } from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import { VisibleContentPreference } from "../../interfaces/VisibleContentPreference";
import Filter from "./icon/Filter";
import styles from "./visibleColumns.module.scss";

const isPresent = (id: string, visibleIds: ReadonlyArray<string>): boolean => {
  return visibleIds.includes(id);
};

const isFiltered = (
  id: string,
  removeFilterWhenHidden: boolean,
  filteredIds: ReadonlyArray<string>,
  visibleIds: ReadonlyArray<string>
): boolean => {
  return filteredIds.includes(id) && (!removeFilterWhenHidden || visibleIds.includes(id));
};

interface VisibleColumnsSelectorProps extends VisibleContentPreference {
  /**
   * Triggered when columns visibility is updated.
   */
  onChange: (visibleColumns: ReadonlyArray<string>, filteredColumns: ReadonlyArray<string>) => void;

  /**
   * The columns that are currently visible in the data grid.
   */
  visibleColumns?: ReadonlyArray<string>;

  /**
   * The columns that currently have any filter applied in the data grid.
   */
  filteredColumns?: ReadonlyArray<string>;
}

const VisibleColumnsSelector: React.FC<VisibleColumnsSelectorProps> = ({
  title,
  description,
  optionsGroup,
  onChange,
  removeFilterWhenHidden = false,
  visibleColumns = [],
  filteredColumns = [],
}): JSX.Element => {
  const onToggle = (id: string): void => {
    if (isPresent(id, visibleColumns)) {
      onChange(
        visibleColumns.filter((currentId) => currentId !== id),
        removeFilterWhenHidden
          ? filteredColumns.filter((currentId) => currentId !== id)
          : filteredColumns
      );
    } else {
      onChange([...visibleColumns, id], filteredColumns);
    }
  };

  return (
    <div>
      {title}
      {description && <div className={styles["header-description"]}>{description}</div>}
      <div className={styles["header-label"]}>{optionsGroup.label}</div>
      <div className={styles["option-group"]}>
        {optionsGroup.options.map((option) => {
          return (
            <div className={styles.option} key={option.id}>
              <div className={styles["option-label"]}>{option.label}</div>
              <div className={styles["option-toggle"]}>
                {isFiltered(option.id, removeFilterWhenHidden, filteredColumns, visibleColumns) && (
                  <div className={styles["filter-icon"]} data-testid="filter-icon">
                    <Filter />
                  </div>
                )}
                <Toggle
                  onChange={() => onToggle(option.id)}
                  checked={isPresent(option.id, visibleColumns)}
                  disabled={option.editable === false}
                  name={option.id}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VisibleColumnsSelector;
