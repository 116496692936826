import {
  Box,
  Container,
  Header,
  NonCancelableCustomEvent,
  RadioGroup,
  RadioGroupProps,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import SortOrder from "../../enums/SortOrder";

interface FieldSortProps {
  header: React.ReactNode;
  sortOrder: SortOrder;
  ascendingLabel?: string;
  descendingLabel?: string;
  onSortOrderChange: (sortOrder: SortOrder) => void;
}

function getOptions(
  ascendingLabel: string,
  descendingLabel: string
): RadioGroupProps.RadioButtonDefinition[] {
  return [
    { value: SortOrder.ASC, label: ascendingLabel },
    { value: SortOrder.DESC, label: descendingLabel },
  ];
}

const FieldSort: React.FC<FieldSortProps> = ({
  header,
  sortOrder,
  ascendingLabel = "A - Z (Ascending sort)",
  descendingLabel = "Z - A (Descending sort)",
  onSortOrderChange,
}): JSX.Element => {
  return (
    <Container header={<Header variant="h2">{header}</Header>}>
      <Box margin={{ left: "xxxs" }}>
        <RadioGroup
          onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => {
            onSortOrderChange(event.detail.value as SortOrder);
          }}
          value={sortOrder}
          items={getOptions(ascendingLabel, descendingLabel)}
          ariaRequired
        />
      </Box>
    </Container>
  );
};

export default FieldSort;
