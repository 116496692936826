import React, { useState } from "react";
import { InputProps, Input } from "@amzn/awsui-components-react-v3";
import { FieldEditorProps } from "../../data-grid/interfaces/FieldEditorProps";

const ENTER_KEY_CODE = 13;

const NumberEditor = ({ currentValue, onChange }: FieldEditorProps<string>): JSX.Element => {
  const [value, setValue] = useState<string>(currentValue);

  const onEnterPress = (event: CustomEvent<InputProps.KeyDetail>): void => {
    const { keyCode } = event.detail;
    if (keyCode === ENTER_KEY_CODE) {
      event.stopPropagation();
      event.preventDefault();
      onChange(value);
    }
  };

  return (
    <Input
      onChange={({ detail }) => setValue(detail.value)}
      value={value}
      type="number"
      placeholder="Enter a value and hit enter to save"
      onKeyDown={onEnterPress}
    />
  );
};

export default NumberEditor;
