import { Box, Container } from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import SelectAllState from "../../enums/SelectAllState";
import { Option } from "../../interfaces/Option";
import MultiSelectOption from "../MultiSelectOption/MultiSelectOption";
import SelectAllOption, {
  SELECT_ALL_LABEL,
  SELECT_ALL_DISPLAYED_RESULTS_LABEL,
} from "../SelectAllOption/SelectAllOption";

import styles from "./multiSelectElement.module.scss";

interface MultiSelectProps {
  isLoading: boolean;
  loadingOptions: string | JSX.Element;
  emptyOptions: string | JSX.Element;
  options: Option[];
  selectedOptions: Option[];
  selectAllState: SelectAllState;
  selectAllDisplayedResultsState: SelectAllState;
  onSelectionUpdate: (isChecked: boolean, option: Option) => void;
  selectAllLabel?: string;
  selectAllDisplayedResultsLabel?: string;
  multiSelectClassName?: string;
  filterText?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  isLoading,
  loadingOptions,
  emptyOptions,
  options,
  selectedOptions,
  selectAllState,
  selectAllDisplayedResultsState,
  selectAllLabel = SELECT_ALL_LABEL,
  selectAllDisplayedResultsLabel = SELECT_ALL_DISPLAYED_RESULTS_LABEL,
  multiSelectClassName,
  onSelectionUpdate,
  filterText,
}): JSX.Element => {
  const multiSelectOptions = options.map((option: Option) => (
    <MultiSelectOption
      key={option.value}
      value={option.value}
      label={option.label}
      isChecked={
        selectAllState === SelectAllState.ALL ||
        selectedOptions.some((selection: Option) => selection.value === option.value)
      }
      onSelectionChange={onSelectionUpdate}
    />
  ));

  return (
    <Container disableContentPaddings className={multiSelectClassName}>
      {isLoading && <Box margin="xxs">{loadingOptions}</Box>}
      {!isLoading && options.length === 0 && <Box margin="xxs">{emptyOptions}</Box>}
      {!isLoading && options.length > 0 && (
        <Box className={styles["box-container"]}>
          <SelectAllOption
            key={selectAllLabel}
            selectAllState={selectAllState}
            onSelectAllUpdate={(selectAll: SelectAllState) => {
              onSelectionUpdate(selectAll === SelectAllState.ALL, {
                label: selectAllLabel,
                value: SELECT_ALL_LABEL,
              });
            }}
            customLabel={selectAllLabel}
          />
          {filterText && (
            <SelectAllOption
              key={selectAllDisplayedResultsState}
              selectAllState={selectAllDisplayedResultsState}
              onSelectAllUpdate={(selectAll: SelectAllState) => {
                onSelectionUpdate(selectAll === SelectAllState.ALL, {
                  label: selectAllLabel,
                  value: SELECT_ALL_DISPLAYED_RESULTS_LABEL,
                });
              }}
              customLabel={selectAllDisplayedResultsLabel}
            />
          )}

          {multiSelectOptions}
        </Box>
      )}
    </Container>
  );
};

export default MultiSelect;
