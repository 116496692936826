import {
  NonCancelableCustomEvent,
  TextFilter,
  TextFilterProps,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";

interface SearchBarProps {
  filterText: string;
  onTextChange: (text: string) => void;
  onDelayedTextChange: (text: string) => void;
  placeholderText?: string;
  customClassName?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  filterText,
  onTextChange,
  onDelayedTextChange,
  placeholderText,
  customClassName,
}): JSX.Element => {
  return (
    <TextFilter
      className={customClassName || ""}
      filteringText={filterText}
      filteringPlaceholder={placeholderText}
      onChange={(event: NonCancelableCustomEvent<TextFilterProps.ChangeDetail>) => {
        onTextChange(event.detail.filteringText);
      }}
      onDelayedChange={(event: NonCancelableCustomEvent<TextFilterProps.ChangeDetail>) => {
        onDelayedTextChange(event.detail.filteringText);
      }}
      filteringAriaLabel="Search Bar"
    />
  );
};

export default SearchBar;
