import React from "react";
import { FilterOptions, SelectedFilterOptions } from "../interfaces/DataGridSortAndFilterProps";
import { mergeFilterOptions } from "../utils/sortAndFilterUtils";

const mergeOptions = (
  filterOptions: FilterOptions,
  selectedFilterOptions: SelectedFilterOptions,
  hasFilterText: boolean
): FilterOptions => {
  if (hasFilterText) {
    return filterOptions;
  }
  return {
    ...filterOptions,
    options: mergeFilterOptions(filterOptions.options, selectedFilterOptions?.options),
  };
};

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const reference = React.useRef<T>();
  React.useEffect(() => {
    reference.current = value;
  });
  return reference.current;
};

interface MergedFilterOptionsProps {
  filterOptions: FilterOptions;
  selectedFilterOptions: SelectedFilterOptions;
  hasFilterText: boolean;
  isVisible: boolean;
}

export const useMergedFilterOptions = ({
  filterOptions,
  selectedFilterOptions,
  hasFilterText,
  isVisible,
}: MergedFilterOptionsProps): FilterOptions => {
  const previousIsVisible = usePrevious(isVisible);
  const previousFilterOptions = usePrevious(filterOptions);
  const [mergedFilterOptions, setMergedFilterOptions] = React.useState<FilterOptions>(() =>
    mergeOptions(filterOptions, selectedFilterOptions, hasFilterText)
  );

  React.useEffect(() => {
    if (filterOptions !== previousFilterOptions || isVisible !== previousIsVisible) {
      setMergedFilterOptions(mergeOptions(filterOptions, selectedFilterOptions, hasFilterText));
    }
  }, [
    filterOptions,
    previousFilterOptions,
    previousIsVisible,
    selectedFilterOptions,
    hasFilterText,
    isVisible,
  ]);

  return mergedFilterOptions;
};

export default useMergedFilterOptions;
