import { useCallback, useEffect, useState } from "react";
import {
  SELECT_ALL_DISPLAYED_RESULTS_LABEL,
  SELECT_ALL_LABEL,
} from "../elements/SelectAllOption/SelectAllOption";
import SelectAllState from "../enums/SelectAllState";
import SortOrder from "../enums/SortOrder";
import { FilterOptions, SelectedFilterOptions } from "../interfaces/DataGridSortAndFilterProps";
import { Option } from "../interfaces/Option";
import {
  calculateSelectAllStateFromSelection,
  getSelectedOptions,
} from "../utils/sortAndFilterUtils";

export interface SelectionDetails {
  isChecked: boolean;
  selectedOption: Option;
  filterOptions: FilterOptions;
  hasFilterText: boolean;
}

interface UseSortAndSelectedItemsState {
  selectedSortOrder: SortOrder;
  selectedFilterOptions: SelectedFilterOptions;
  selectAllDisplayedResultsState?: SelectAllState;
}

interface UseSortAndSelectedItemsActions {
  updateSortOrder: (sort: SortOrder) => void;
  updateSelectedFilterOptions: (selection: SelectionDetails) => void;
  reset: () => void;
}

function getUpdatedSelectedOptions(
  isFilterTextSelectAll: boolean,
  details: SelectionDetails,
  selectedFilterOptions: SelectedFilterOptions
): SelectedFilterOptions {
  const include =
    (selectedFilterOptions.in && details.isChecked) ||
    (!selectedFilterOptions.in && !details.isChecked);

  const selectedOptions = getSelectedOptions(
    include,
    selectedFilterOptions.options,
    isFilterTextSelectAll ? details.filterOptions.options : [details.selectedOption]
  );

  const selectAllState = calculateSelectAllStateFromSelection(
    {
      ...selectedFilterOptions,
      options: selectedOptions,
    },
    details.filterOptions,
    details.hasFilterText
  );

  return {
    in: selectedFilterOptions.in,
    options: selectedOptions,
    selectAllState,
  };
}

function getUpdatedSelectedOptionsOnSelectAll(details: SelectionDetails): SelectedFilterOptions {
  const selectAllState = details.isChecked ? SelectAllState.ALL : SelectAllState.NONE;
  return {
    in: selectAllState !== SelectAllState.ALL,
    options: [],
    selectAllState,
  };
}

const DEFAULT_SELECTED_FILTER_OPTIONS = {
  in: false,
  options: [],
  selectAllState: SelectAllState.ALL,
};

/**
 * Hook that returns a selected sort order and filter options.
 *
 * Note: The selectedFilterOptions contains list of checked/unchecked options depending on `in` flag
 * @param {SortOrder} selectedSortOrder = SortOrder.NONE by default
 * @param {SelectedFilterOptions} selectedFilterOptions = {
    in: false,
    options: [],
    selectAllState: SelectAllState.ALL,
  } by default
 * @param {SelectAllState} selectAllDisplayedResultsState = SelectAllState.ALL by default
 * @returns {UseSortAndSelectedItemsState & UseSortAndSelectedItemsActions} =
 * {selectedSortOrder, selectedFilterOptions, updateSortOrder, updateSelectedFilterOptions, selectAllDisplayedResultsState}
 */
const useSelectedSortAndFilterOptions = (
  selectedSortOrder: SortOrder = SortOrder.NONE,
  selectedFilterOptions: SelectedFilterOptions = DEFAULT_SELECTED_FILTER_OPTIONS,
  selectAllDisplayedResultsState: SelectAllState = SelectAllState.INITIAL
): UseSortAndSelectedItemsState & UseSortAndSelectedItemsActions => {
  const [currentSortOrder, setSortOrder] = useState<SortOrder>(selectedSortOrder);
  const [currentSelectedFilterOptions, setSelectedFilterOptions] = useState<SelectedFilterOptions>(
    selectedFilterOptions
  );
  const [
    currentSelectAllDisplayedResultsState,
    setSelectAllDisplayedResultsState,
  ] = useState<SelectAllState>(selectAllDisplayedResultsState);

  const updateSortOrder = (sort: SortOrder): void => {
    setSortOrder(sort);
  };

  const updateSelectedFilterOptions = (details: SelectionDetails): void => {
    const updatedSelectedOptions =
      (details.selectedOption.value as string) === SELECT_ALL_LABEL
        ? getUpdatedSelectedOptionsOnSelectAll(details)
        : getUpdatedSelectedOptions(
            (details.selectedOption.value as string) === SELECT_ALL_DISPLAYED_RESULTS_LABEL,
            details,
            currentSelectedFilterOptions
          );
    const selectAllStateFromIsChecked = details.isChecked
      ? SelectAllState.ALL
      : SelectAllState.NONE;
    const updatedSelectAllDisplayedResultsState =
      (details.selectedOption.value as string) === SELECT_ALL_LABEL
        ? selectAllStateFromIsChecked
        : updatedSelectedOptions.selectAllState;

    setSelectedFilterOptions(updatedSelectedOptions);
    setSelectAllDisplayedResultsState(updatedSelectAllDisplayedResultsState);
  };

  const reset = useCallback((): void => {
    setSortOrder(selectedSortOrder);
    setSelectedFilterOptions(selectedFilterOptions);
    setSelectAllDisplayedResultsState(selectAllDisplayedResultsState);
  }, [selectedSortOrder, selectedFilterOptions, selectAllDisplayedResultsState]);

  // If parent changes props, reset the state
  useEffect(() => {
    reset();
  }, [reset]);

  return {
    selectedSortOrder: currentSortOrder,
    selectedFilterOptions: currentSelectedFilterOptions,
    updateSortOrder,
    updateSelectedFilterOptions,
    reset,
    selectAllDisplayedResultsState: currentSelectAllDisplayedResultsState,
  };
};

export default useSelectedSortAndFilterOptions;
