export enum PopoverPlacement {
  below,
  right,
}

export interface CellPopoverProps {
  /**
   * Absolute row index (0-based. It must account for the header, so in that case the actual first row of data would be index 1).
   */
  rowIdx: number;

  /**
   * Absolute column index (0-based. If there is a selection-column, the first actual column of data would be index 1).
   */
  columnIdx: number;

  /**
   * Placement of the popover relative to the cell.
   */
  placement: PopoverPlacement;

  /**
   * Content to be displayed in the cell's popover container.
   */
  content: React.ReactNode;

  /**
   * Sets the visibility of the popover
   */
  isVisible: boolean;

  /**
   * Target to attach the popover top. If not provided, PolarisDataGrid will use a default attachment element.
   */
  popoverTarget?: HTMLElement;
}
