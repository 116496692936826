import { Button, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import styles from "./sortAndFilterFooter.module.css";

interface SortAndFilterFooterProps {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const SortAndFilterFooter: React.FC<SortAndFilterFooterProps> = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}): JSX.Element => {
  return (
    <SpaceBetween
      direction="horizontal"
      size="xs"
      className={styles["table-sort-and-filter-footer"]}
    >
      <Button data-testid="confirm-button" variant="link" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button data-testid="cancel-button" variant="primary" onClick={onConfirm}>
        {confirmLabel}
      </Button>
    </SpaceBetween>
  );
};

export default SortAndFilterFooter;
