import React from "react";

const Filter = (): JSX.Element => {
  return (
    <svg
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon/Symbol/Filter_solid</title>
      <defs>
        <path
          d="M15.0019112,0 C15.8497488,0 16.3129049,0.988857477 15.7701324,1.6401844 L11.0019112,7.36204994 L11.0019112,12 C11.0019112,12.3787721 10.7879088,12.7250352 10.4491247,12.8944272 L6.44912475,14.8944272 C5.78422365,15.2268777 5.00191115,14.743382 5.00191115,14 L5.00191115,7.36204994 L0.233689873,1.6401844 C-0.309082563,0.988857477 0.154073504,0 1.00191115,0 L15.0019112,0 Z"
          id="path-1"
        />
      </defs>
      <g id="Icon/Symbol/Filter_solid" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon/Symbol/Filter">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use id="Icon" fill="#545B64" xlinkHref="#path-1" />
        </g>
      </g>
    </svg>
  );
};

export default Filter;
