import {
  Box,
  Checkbox,
  CheckboxProps,
  NonCancelableCustomEvent,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import React from "react";
import SelectAllState from "../../enums/SelectAllState";
import { getSelectAllState } from "../../utils/sortAndFilterUtils";
import styles from "../MultiSelect/multiSelect.module.scss";

interface SelectAllOptionProps {
  selectAllState: SelectAllState;
  onSelectAllUpdate: (state: SelectAllState) => void;
  customLabel?: string;
}

export const SELECT_ALL_LABEL = "(Select all)";
export const SELECT_ALL_DISPLAYED_RESULTS_LABEL = "(Select all displayed results)";

const SelectAllOption: React.FC<SelectAllOptionProps> = ({
  selectAllState,
  onSelectAllUpdate,
  customLabel = SELECT_ALL_LABEL,
}): JSX.Element => {
  return (
    <Box
      padding="xxs"
      className={selectAllState === SelectAllState.ALL ? styles["box-checked"] : styles.box}
    >
      <SpaceBetween direction="horizontal" size="xs">
        <Checkbox
          key={SELECT_ALL_LABEL}
          checked={selectAllState === SelectAllState.ALL}
          indeterminate={selectAllState === SelectAllState.PARTIAL}
          onChange={(event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
            const value = getSelectAllState(event.detail.checked, event.detail.indeterminate);
            onSelectAllUpdate(value);
          }}
        >
          {customLabel}
        </Checkbox>
      </SpaceBetween>
    </Box>
  );
};

export default SelectAllOption;
