import { Box, Button, ColumnLayout, Modal } from "@amzn/awsui-components-react-v3/polaris";
import React, { useState } from "react";
import PageSizeSelector from "../../elements/PageSizeSelector/PageSizeSelector";
import VisibleColumnsSelector from "../../elements/VisibleColumnsSelector/VisibleColumnsSelector";
import { DataGridPreferencesProps } from "../../interfaces/DataGridPreferencesProps";
import { Preferences } from "../../interfaces/Preferences";
import styles from "./DataGridPreferences.module.scss";

const copyPreferences = ({
  pageSize,
  visibleContent,
  filteredContent,
}: Preferences): Preferences => ({
  pageSize,
  visibleContent,
  filteredContent,
});

const mergePreferences = (newPref: Preferences, oldPref: Preferences): Preferences => ({
  pageSize: newPref.pageSize !== undefined ? newPref.pageSize : oldPref.pageSize,
  visibleContent:
    newPref.visibleContent !== undefined ? newPref.visibleContent : oldPref.visibleContent,
  filteredContent:
    newPref.filteredContent !== undefined ? newPref.filteredContent : oldPref.filteredContent,
});

export const DataGridPreferences: React.FC<DataGridPreferencesProps> = ({
  title,
  confirmLabel,
  cancelLabel,
  disabled = false,
  pageSizePreference,
  visibleContentPreference,
  preferences = {},
  dontExpandToFit = false,
  onConfirm,
  onCancel,
}): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const [temporaryPreferences, setTemporaryPreferences] = useState(copyPreferences(preferences));

  const showModal = (): void => {
    setTemporaryPreferences(copyPreferences(preferences));
    setModalVisible(true);
  };

  const onConfirmListener = (): void => {
    setModalVisible(false);
    if (onConfirm) {
      onConfirm(temporaryPreferences);
    }
  };

  const onCancelListener = (): void => {
    if (onCancel) {
      onCancel();
    }
    setModalVisible(false);
    setTemporaryPreferences(copyPreferences(preferences));
  };

  const onPreferenceChange = (changedPreferences: Preferences): void => {
    setTemporaryPreferences(mergePreferences(changedPreferences, temporaryPreferences));
  };

  return (
    <div>
      <Button
        data-testid="settings-icon"
        variant="icon"
        iconName="settings"
        onClick={showModal}
        disabled={disabled}
      />
      <Modal
        data-testid="modal-test"
        visible={modalVisible}
        size="large"
        header={title}
        onDismiss={onCancelListener}
        footer={
          <Box float="right">
            <Button variant="link" onClick={onCancelListener} data-testid="cancel-button">
              {cancelLabel}
            </Button>
            <Button variant="primary" onClick={onConfirmListener} data-testid="confirm-button">
              {confirmLabel}
            </Button>
          </Box>
        }
        className={dontExpandToFit ? styles["dont-expand-to-fit"] : ""}
      >
        <ColumnLayout borders="vertical" columns={2} variant="text-grid">
          {pageSizePreference && (
            <PageSizeSelector
              title={pageSizePreference.title}
              options={pageSizePreference.options}
              value={temporaryPreferences.pageSize}
              onChange={(pageSize) => onPreferenceChange({ pageSize })}
            />
          )}
          {visibleContentPreference && (
            <VisibleColumnsSelector
              title={visibleContentPreference.title}
              description={visibleContentPreference.description}
              optionsGroup={visibleContentPreference.optionsGroup}
              removeFilterWhenHidden={visibleContentPreference.removeFilterWhenHidden}
              onChange={(visibleColumns, filteredColumns) => {
                onPreferenceChange({
                  visibleContent: visibleColumns,
                  filteredContent: filteredColumns,
                });
              }}
              visibleColumns={temporaryPreferences.visibleContent}
              /* We update filtered columns in temporary preferences but do not pass that here because
              we want to get the filter back when column is turned back on before confirm button is pressed. */
              filteredColumns={preferences.filteredContent}
            />
          )}
        </ColumnLayout>
      </Modal>
    </div>
  );
};

export default DataGridPreferences;
