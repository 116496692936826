import React from "react";
import { createPortal } from "react-dom";
import { PortalProps } from "../../data-grid/interfaces/PortalProps";

export interface CellPortalProps {
  children: React.ReactNode;
  portalProps: PortalProps;
  className?: string;
}

/**
 * A helper component that allows grid cells to overflow. This is used by components like
 * `<FreeTextEditor />`, which needs to render a textarea outside of its cell.
 *
 * @param props an object containing `children` to be rendered in the portal
 * in addition to a set of properties supplied by `react-data-grid`
 */
export default function CellPortal({
  portalProps,
  children,
  className,
}: CellPortalProps): React.ReactPortal {
  return createPortal(
    <div style={portalProps.cssProps} className={className}>
      {children}
    </div>,
    portalProps.portalTarget
  );
}
