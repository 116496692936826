import { useRef, useCallback } from "react";
import { FillEvent as ReactDataGridFillEvent, RowsChangeData } from "react-data-grid/lib/index";
import { FillEvent } from "../interfaces/FillEvent";

interface UseDragToFillResult<T> {
  onRowsChange: ((rows: T[], data: RowsChangeData<T>) => void) | undefined;
  handleFill: (({ columnKey, sourceRow, targetRow }: ReactDataGridFillEvent<T>) => T) | undefined;
}

export const shouldUpdateRowsForDragToFill = <T extends unknown>(
  onFillCallback: ((event: FillEvent<T>) => void) | undefined,
  isDragToFillActive: boolean
): boolean => {
  return !!onFillCallback && isDragToFillActive;
};

const useDragToFill = <T extends unknown>(
  onFill: ((event: FillEvent<T>) => void) | undefined
): UseDragToFillResult<T> => {
  const dragSourceRowReference = useRef<T>();
  const isDragToFillActiveReference = useRef(false);

  const onRowsChange = useCallback(
    (rows: T[], data: RowsChangeData<T>): void => {
      if (shouldUpdateRowsForDragToFill(onFill, isDragToFillActiveReference.current)) {
        const updatedRows: T[] = [];
        data.indexes.forEach((index) => updatedRows.push(rows[index]));
        (onFill as (event: FillEvent<T>) => void)({
          columnKey: data.column.key,
          sourceRow: dragSourceRowReference.current as T,
          updatedRows,
        });
        isDragToFillActiveReference.current = false;
        dragSourceRowReference.current = undefined;
      }
    },
    [onFill]
  );

  const handleFill = ({ columnKey, sourceRow, targetRow }: ReactDataGridFillEvent<T>): T => {
    dragSourceRowReference.current = sourceRow;
    isDragToFillActiveReference.current = true;
    const updatedRow = {
      ...(targetRow as Record<string, unknown>),
      [columnKey]: sourceRow[columnKey as keyof T],
    } as T;
    return updatedRow;
  };

  return {
    onRowsChange: onFill ? onRowsChange : undefined,
    handleFill: onFill ? handleFill : undefined,
  };
};

export default useDragToFill;
