import { Container } from "@amzn/awsui-components-react-v3";
import React from "react";
import CellPortal from "../../../common/CellPortal/CellPortal";
import { EditorContainerProps } from "../../../data-grid/interfaces/EditorContainerProps";

const EditorContainer = <T extends unknown, C extends unknown>({
  editorProps,
  editor: EditorComponent,
  statusBox,
  onValueChange,
  className,
}: EditorContainerProps<T, C>): JSX.Element => {
  const { row, column, portalProps } = editorProps;
  return (
    <CellPortal portalProps={portalProps}>
      <Container disableContentPaddings disableHeaderPaddings className={className}>
        {statusBox}
        <EditorComponent
          currentValue={(row as Record<string, C>)[column.id]}
          onChange={(value: C) => {
            if (onValueChange) {
              onValueChange(row, column.id, value);
            }
          }}
        />
      </Container>
    </CellPortal>
  );
};

export default EditorContainer;
