import React from "react";
import CellPortal from "../../../common/CellPortal/CellPortal";
import { PortalProps } from "../../interfaces/PortalProps";

export interface CellPopoverRenderProps {
  portalProps: PortalProps;
  content: React.ReactNode;
  className?: string;
}

export const CellPopover = ({
  content,
  portalProps,
  className,
}: CellPopoverRenderProps): JSX.Element => {
  return (
    <CellPortal portalProps={portalProps} className={className}>
      {content}
    </CellPortal>
  );
};

export default CellPopover;
