import { Box, FormField, RadioGroup } from "@amzn/awsui-components-react-v3";
import React from "react";
import { PageSizePreference } from "../../interfaces/PageSizePreferences";

interface PageSizeSelectorProps extends PageSizePreference {
  value?: number;
  onChange: (pageSize: number) => void;
}

const PageSizeSelector: React.FC<PageSizeSelectorProps> = ({
  title,
  options,
  value: currentValue,
  onChange,
}): JSX.Element => {
  return (
    <div>
      <Box>
        <FormField label={title} stretch>
          <RadioGroup
            onChange={(event) => onChange(parseInt(event.detail.value, 10))}
            value={`${currentValue}`}
            items={options.map(({ label, value }) => ({ label, value: `${value}` }))}
          />
        </FormField>
      </Box>
    </div>
  );
};

export default PageSizeSelector;
